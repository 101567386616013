import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mq, fluidType } from '../../helpers'

const ButtonWrapper = styled.button`
  ${fluidType(12, 24)};
  border: none;
  color: white;
  margin: 1rem;
  padding: 0.75rem 2rem;
  border-radius: 10px;
  background: black;
  cursor: pointer;
  border: 2px solid black;
  transition: 0.2s ease-in-out;
  transition-property: background-color, color;

  ${mq.sm`
    padding: 1.25rem 2.5rem;
  `};

  &:hover {
    color: black;
    background: white;
    border: 2px solid black;
  }

  &:active {
    background: black;
    color: white;
  }
`

const Button = ({ children }) => <ButtonWrapper>{children}</ButtonWrapper>

Button.defaultProps = {
  children: 'Button',
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Button
