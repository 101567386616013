/* eslint-disable no-undef */
module.exports = {
  siteTitle: 'Damiano Plebani',
  siteTitleShort: 'DP',
  siteDescription: 'Personal site',
  siteUrl: 'https://damianoplebani-wip.netlify.com',
  //sitePagesTest: ['/', '/about'],
  themeColor: '#000',
  backgroundColor: '#fff',
  social: {
    twitter: 'dmnplb',
  },
}
