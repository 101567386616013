import React from 'react'
import PropTypes from 'prop-types'
//import { graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import { mq } from '../helpers'
import './PageWrapper.style.css'

import { Head, MainNav } from '../components/organisms'

const GlobalStyle = createGlobalStyle`
  body {
    background-image: linear-gradient(to right, #74ebd5, #acb6e5);;
    margin: 0; 
  }

  /* Uncomment to enable DEBUG MODE */
  /*
  * { background-color: rgba(255,0,0,.2); }
  * * { background-color: rgba(0,255,0,.2); }
  * * * { background-color: rgba(0,0,255,.2); }
  * * * * { background-color: rgba(255,0,255,.2); }
  * * * * * { background-color: rgba(0,255,255,.2); }
  * * * * * * { background-color: rgba(255,255,0,.2); }
  * * * * * * * { background-color: rgba(255,0,0,.2); }
  * * * * * * * * { background-color: rgba(0,255,0,.2); }
  * * * * * * * * * { background-color: rgba(0,0,255,.2); } */
`

const PageWrapEl = styled.div`
  background-color: white;
  min-height: calc(100vh - 2rem);
  box-sizing: border-box;
  padding: 1rem;
  margin: 1rem;

  ${mq.sm`
    min-height: calc(100vh - 2rem);
    padding: 1rem;
    margin: 1rem;
  `};
`

const PageWrapper = ({ title, children }) => (
  <>
    <GlobalStyle />
    <Head pageTitle={title} />

    <PageWrapEl>
      <h3>Site Title</h3>
      <MainNav />
      {children}
    </PageWrapEl>
  </>
)

PageWrapper.displayName = 'PageWrapper'

PageWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
}

export default PageWrapper
