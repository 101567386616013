import React from 'react'
import PropTypes from 'prop-types'
import { Title, Meta, Link } from 'react-head'

import { siteTitle } from '../../config/site-config'

const Head = ({ pageTitle }) => (
  <>
    <Title>{pageTitle ? pageTitle : siteTitle}</Title>
    <Meta name="description" content="Lorem ipsum dolor sit amet." />
    <Link rel="canonical" content="https://bejamas.io/" />
  </>
)

Head.propTypes = {
  pageTitle: PropTypes.string,
}

export default Head
