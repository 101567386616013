import { css } from 'styled-components'
import breakpoints from '../tokens/mqBreakpoints'

const fluidType = (minFontSize, maxFontSize) => {
  // Configuration
  const minScreenSize = breakpoints.xsm
  const maxScreenSize = breakpoints.xlg

  // Helper variables
  const diffBetweenFontSizes = `${maxFontSize} - ${minFontSize}`
  const diffBetweenScreenSizes = `${maxScreenSize} - ${minScreenSize}`

  return css`
    font-size: ${minFontSize}px;

    @media (min-width: ${minScreenSize}px) and (max-width: ${maxScreenSize}px) {
      font-size: calc(
        ${minFontSize}px + (${diffBetweenFontSizes}) *
          ((100vw - ${minScreenSize}px) / (${diffBetweenScreenSizes}))
      );
    }

    @media (min-width: ${maxScreenSize}px) {
      font-size: ${maxFontSize}px;
    }
  `
}

export default fluidType
