import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from 'gatsby'

const MainNavStyle = styled.ul`
  list-style: none;
`

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 1rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }

  &.active {
    background-color: black;
    color: white;
  }
`

const ListLink = ({ children, to }) => (
  <li style={{ display: 'inline-block', marginRight: '1rem' }}>
    <StyledLink activeClassName="active" to={to} replace>
      {children}
    </StyledLink>
  </li>
)

const MainNav = () => (
  <MainNavStyle>
    <ListLink to="/">Home</ListLink>
    <ListLink to="/about">About</ListLink>
    <ListLink to="/test">Test MDx</ListLink>
    <ListLink to="/projects">Projects</ListLink>
  </MainNavStyle>
)

ListLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
}

export default MainNav
