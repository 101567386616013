import { css } from 'styled-components'
import breakpoints from '../tokens/mqBreakpoints'

const mq = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export default mq
